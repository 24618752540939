// src/App.js
import React from 'react';
//import WebViewer from './WebViewer';
import StaticContent from './StaticContent';

function App() {
  return (
    <div className="App">
     
      <StaticContent />
    </div>
  );
}

export default App;
