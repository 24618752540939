// src/components/StaticContent.js
import React from 'react';

const StaticContent = () => {
  return (
    <div>
        <h1>HOLA MUNDO</h1>
        
    </div>
  );
};

export default StaticContent;
